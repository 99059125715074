import { Stack, Text } from '@fluentui/react';
import React from 'react';
import CompareChart from './CompareChart';
import PlanForm from './PlanForm';
import usePlan from './usePlan';

function App() {
  const [planA, setPlanA] = usePlan('A');
  const [planB, setPlanB] = usePlan('B');
  return (
    <Stack horizontalAlign="center">
      <Stack.Item>
        <Stack wrap horizontal tokens={{childrenGap: '2rem'}}>
          <Stack.Item basis={200} grow>
            <PlanForm plan={planA} setters={setPlanA} />
          </Stack.Item>
          <Stack.Item basis={200} grow>
            <PlanForm plan={planB} setters={setPlanB} />
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <Text as="p">
          <Text style={{ fontWeight: "bold" }}>Family Plan Note:</Text> Family plans are treated differently in Bronze+ and Silver than they are in other plans.  Bronze, Gold, and Plat each family member has
          their own, personal deductable and out of pocket max in addition to the family.  This is better because if an individual in the family incurrs a lot of costs, then you get coverage sooner.  So,
          to compare, toggle the family plan on and off and imagine the situation where only one person has a lot of costs vs when multiple family members have a lot of costs. Cancer vs Car Wreck if you will.
        </Text>
      </Stack.Item>
      <Stack.Item>
        <div style={{width: '100vw', height: '50vw'}}>
          <CompareChart planA={planA} planB={planB} />
        </div>
      </Stack.Item>
    </Stack>
  );
}

export default App;
